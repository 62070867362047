<template>
  <div>


    <div
        style="position: fixed;top:0;left:0;right:0;bottom:0;background: rgba(0,0,0,0.8);z-index: 500;display: flex;flex-wrap: wrap;justify-content: center;align-items: center"
        v-if="isLoading">
      <div style="width: 100%;display: flex;flex-wrap: wrap;justify-content: center;align-items: center;cursor:pointer;"
           title="卡住点击此处跳过加载" @click="$router.push('/getsteamlibrary')">
        <a-progress :stroke-color="{
        '0%': '#108ee9',
        '100%': '#87d068',
      }" :strokeWidth="6" type="circle" :percent="percent" style=""/>
        <div style="width: 100%;text-align: center;margin-top: 15px;font-size: 25px;color: darkcyan">
          {{ loadingText }}
          <a-icon type="loading" style="color: cyan;font-size: 25px"/>
        </div>
      </div>
    </div>


    <div style="position: fixed;right: 20px;bottom: 20px;height: 240px;width: 230px;" v-if="showGG">
      <div style="width: 100%;display: flex;flex-direction: row-reverse;padding-right: 10px;"><p class="cccc"
                                                                                                 @click="showGG=false">
        X</p></div>
      <div style="width: 90%;height:85%;margin: 0 auto">
        <img :src="`${website_info.website_info[0].adv_img}?time+${Math.random()}`" alt="" width="100%"
             height="100%"
             style="border: 1px solid rgba(0,0,0,0.4);box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.4);border-radius: 9px;cursor: pointer;"
             @click="guanggao">
      </div>
    </div>

    <div class="box">


      <inhead></inhead>

      <div class="navigation"></div>
      <div class="navigation"></div>


      <div class="ps_bottom_box">
        <img class="title_img" :src="title" alt="">
        <div class="input_box">
          <input type="text" class="input" placeholder="请输入steam账号17位ID或者库存网页URL"
                 v-model="steamId">
          <div class="input_button" @click="getBagData">确定</div>

          <a-popover v-model="payMentVis" title="温馨提示" trigger="click">
            <template slot="content">
              <div> 是否开启此ID的VIP作图功能 <br>
                开启后扣除一次收费做图次数！
              </div>

              <div style="width: 100%;margin-top: 15px;display: flex;flex-wrap: nowrap;justify-content: space-around">
                <a-button type="danger" size="small" @click="payMentVis=false">取消</a-button>
                <a-button type="primary" size="small" @click="payMent">开启</a-button>
              </div>

            </template>

            <div class="input_button_1">VIP作图</div>
          </a-popover>

        </div>
        <div class="input_box" style="margin: 20px auto 0;">

          <a-radio-group v-model="junk" style="margin: 0 auto">
            <a-radio :value="0" style="color: white">
              隐藏无价值物品
            </a-radio>
            <a-radio :value="1" style="color: white">
              显示无价值物品
            </a-radio>
          </a-radio-group>
        </div>
        <div class="get_id_box">
          <div class="get_id_button_box">
            <div class="get_id_title">如何获取我的17位账号ID？</div>
            <div class="get_id_button" @click="getIdHelp">点击查看</div>
            <div class="get_id_title">如何解除STEAM库存隐私？</div>
            <div class="get_id_button" @click="getBagHelp">点击查看</div>
          </div>
          <img class="get_id_logo" :src="qrcode" alt="" width="100%" height="100%">
        </div>
      </div>


    </div>

    <infoot></infoot>

    <div>

      <a-modal
          title="错误"
          :visible="visible"
          @ok="handleOk"
          @cancel="handleCancel"
          okText="点我跳转教程"
          cancelText="取消"
      >
        <p>{{ ModalText }}</p>
      </a-modal>

    </div>


    <div>

      <a-modal
          title="检测到本地存在未完成的作图记录"
          :visible="localSaver"
          @ok="pusher"
          @cancel="pusherCant"
          okText="继续作图"
          cancelText="取消"
          :maskClosable="false"
      >
        <p style="text-align: center">缓存的id为 <b style="color: red">{{ saverId }}</b></p>
        <p style="text-align: center">点击取消<b style="color: red">会清除</b>之前的缓存，亦可继续做图</p>
      </a-modal>

    </div>


    <div>

      <a-modal
          title="错误"
          :visible="NoVip"
          @cancel="NoVip=false"
          :footer="null"
      >
        <p>网站右上角注册会员登录后使用! 如果已登录点击账号退出重新登录！</p>
      </a-modal>

    </div>
    <div>

      <a-modal
          title="温馨提示"
          :visible="TIPS"
          @ok="$router.push('/personality/Payment')"
          @cancel="TIPS=false"
          okText="前往充值"
          cancelText="取消"
      >
        <p>{{ TIPSTEXT }}</p>
      </a-modal>
    </div>


  </div>

</template>

<script>
import infoot from './infoot'
import inhead from './inhead'
import $ from 'jquery'
import {mapState} from "vuex";

function pc() {
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    $('.navigation').css('margin-top', '20px')
  }
}


const key = "123"

let b = []

export default ({
  components: {
    infoot,
    inhead
  },

  data() {
    return {
      localSaver: false,
      saverId: localStorage.getItem('saverId'),
      qrcode: require('@/assets/qrcode.png'),
      title: require('@/assets/title2.png'),

      isLoading: false,
      count: 0,
      percent: 0,
      loadingText: '正在加载图片...',


      steamId: '',
      HttpSteamId: '',
      showGG: true,
      show: true,
      stackTrace: 0,

      pageCurrentMax: '',
      dataShow: [],
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,

      current: 1,
      junk: 0,
      TIPS: false,
      TIPSTEXT: '',
      payMentVis: false,
      NoVip: false
    }
  },
  methods: {

    pusher() {
      this.localSaver = false
      this.count = 0
      b = JSON.parse(localStorage.getItem('bagBak')).result
      this.isLoading = true
      this.ModalText = '正在加载缓存...'
      this.preload()
    },


    pusherCant() {
      localStorage.removeItem('bagBak')
      localStorage.removeItem('Continue')
      this.localSaver = false
    },

    payMent() {
      if (localStorage.getItem('Continue')) {
        localStorage.removeItem('bagBak')
        localStorage.removeItem('Continue')
      }
      this.HttpSteamId = this.steamId
      if (this.HttpSteamId[0] === 'H' || this.HttpSteamId[0] === 'h') {
        try {
          let id = this.HttpSteamId;
          let re = /\d{17}/
          this.HttpSteamId = id.match(re)[0].trim()
          // console.log(this.HttpSteamId)
          this.payMentDo()
        } catch (e) {
          this.showModal()
          this.ModalText = '请输入完整地址！'
          this.$message.error({content: '支付失败', key, duration: 2});
        }
      } else {
        this.payMentDo()
      }
    },

    payMentDo() {

      if (this.HttpSteamId.trim().length < 17) {
        this.$message.error({content: 'steamId为17位', key})
        return
      }

      this.payMentVis = false
      this.$message.loading({content: '支付中', key})
      this.$axios.post('/user/api/openTheVipFunction', {
        steamid: this.HttpSteamId
      }).then(val => {
        this.$notification.open({
          key,
          message: '温馨提示',
          description:
          val.data.msg,
          icon: <a-icon type="smile" style="color: #108ee9"/>,
        });
        this.$message.loading({content: '支付成功,正在获取数据进行作图', key})

        this.AxiosPack()
      }, reason => {
        if (reason.code == -1) {
          this.$message.error({content: reason.msg, key})
          this.TIPSTEXT = reason.msg
          this.TIPS = true
          return
        }
        this.$message.error({content: reason.msg, key})

      })
    },

    guanggao() {
      const a = document.createElement('a')
      a.href = this.website_info.website_info[0].adv_url
      // alink.download = steamid
      a.click()
    },

    pageCurrentMethod(v) {
      // this.current+=1
      this.$axios.get('/Api/GetPostsList/' + v, {}).then(value => {
        this.dataShow = JSON.parse(value.data.data).pageData
      })
    },


    getIdHelp() {
      this.$router.push('/HelpPc')
    },
    getBagHelp() {
      this.$router.push('/Privacy')
    },


    getBagData() {
      if (localStorage.getItem('AcToken') == '' || localStorage.getItem('AcToken') == undefined) {
        this.NoVip = true
        return
      }

      if (localStorage.getItem('Continue')) {
        localStorage.removeItem('bagBak')
        localStorage.removeItem('Continue')
      }

      this.$message.loading({content: '正在加载中', key, duration: 0});
      this.HttpSteamId = this.steamId
      if (this.HttpSteamId[0] === 'H' || this.HttpSteamId[0] === 'h') {
        try {
          let id = this.HttpSteamId;
          let re = /\d{17}/
          this.HttpSteamId = id.match(re)[0].trim()
          // console.log(this.HttpSteamId)
          this.AxiosPack()
        } catch (e) {
          this.showModal()
          this.ModalText = '请输入完整地址！'
          this.$message.error({content: '获取失败', key, duration: 2});
        }
      } else {
        this.AxiosPack()
      }
    },

    async AxiosPack() {

      if (this.HttpSteamId.trim().length < 17) {
        this.$message.error({content: 'steamId为17位', key})
        return
      }
      this.isLoading = true
      await this.$axios.post('/Api/Image/inquiryPaymentStatus', {
        steamid: this.HttpSteamId
      }).then(val => {
        this.$notification.open({
          key,
          message: '温馨提示',
          description:
          val.data.msg,
          icon: <a-icon type="smile" style="color: #108ee9"/>,
        });
        sessionStorage.setItem('isPay', '1')
      }, () => {
        sessionStorage.setItem('isPay', '0')
      })


      await this.$axios.get('/Api/GetInventory', {
        params: {
          steamid: this.HttpSteamId,
          junk: this.junk
        }
      }).then(value => {
        // console.log(value.data.code)
        this.$store.commit('setBagData', value.data.data)
        const p = JSON.parse(value.data.data)
        sessionStorage.setItem('steamId', this.HttpSteamId)
        sessionStorage.setItem('logid', p.logid)
        b = p.result
        this.preload()
      }, ({msg}) => {
        //todo
        if (this.stackTrace > 2) {
          this.isLoading = false
          this.stackTrace = 0;
          this.ModalText = msg
          this.$message.error({content: msg, key});
          this.showModal()
        } else {
          this.stackTrace++;
          this.AxiosPack();
          this.$message.error({content: '重试中...', key: '123123', duration: 2});
        }


      })
    },
    showModal() {
      this.visible = true;
    },
    handleOk() {
      this.$router.push('/Privacy')
    },
    handleCancel() {
      this.visible = false;
    },

    preload() {
      for (const i of b) {
        let image = new Image()
        image.src = i.icon_url
        image.onload = () => {
          this.count++
          // 计算图片加载的百分数，绑定到percent变量
          let percentNum = Math.floor((this.count / b.length) * 100)
          this.percent = parseInt(percentNum)
          // this.percent = `${percentNum}%`
        }
        image.onerror = () => {
          this.count++
        }
      }
    }

  },
  computed: {
    ...mapState('footer', ['website_info'])
  },
  watch: {
    count() {
      // console.log(val)
      // console.warn(this.count, b.length)
      if (this.count === b.length) {
        console.log(1)
        this.loadingText = '已完成,等待跳转'
        this.isLoading = false
        // 图片加载完成后跳转页面
        this.$router.push({path: '/getsteamlibrary'})
      }

    }
  },

  created() {
    this.show = !(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))
    // this.$axios.get('/Api/GetPostsList/1', {}).then(value => {
    //   this.dataShow = JSON.parse(value.data.data).pageData
    //   this.pageCurrentMax = JSON.parse(value.data.data).count
    // })

    if (localStorage.getItem('Continue')) {
      this.localSaver = true
    }

    const metas = document.getElementsByTagName("meta");
    metas[4].content = 'width=device-width,initial-scale=0.5'
    pc()
  }
})

</script>
<style scoped>

.cccc {
  transition: all 0.5s;
  color: red;
}

.cccc:hover {
  cursor: pointer;
  transform: rotate(360deg) scale(1.2);
  color: #00b7ee;
}


a {
  text-decoration: none;
}

.box {
  width: 100%;
  height: 100vh;
  background: url('~@/assets/bg_innerImg.jpg');
  background-size: 100% 100%;
}

.navigation {
  width: 100%;
  height: 48px;
}


.ps_bottom_box {
  width: 669px;
  background: rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(255, 255, 266, 0.26);
  border-radius: 21px;
  margin: 4% auto 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title_img {
  width: 56.28%;
  /*height: 17.08%;*/
  margin: 10% auto 0;

}

.input_box {
  display: flex;
  /*margin: 0 auto;*/
  margin-top: 43px;
  margin-left: 70px;
}

.input {
  width: 63.5%;
  height: 49px;
  background: rgba(0, 0, 0, 0.33);
  border: 1px solid #00A0E9;
  border-radius: 33px 0 0 33px;
  font-size: 13px;
  font-weight: 300;
  color: #0DAAF5;
  padding-left: 17px;

}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #00A0E9;
  opacity: 1;
}


::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #00A0E9;
  opacity: 1;
}


input:-ms-input-placeholder {
  color: #00A0E9;
  opacity: 1;
}


input::-webkit-input-placeholder {
  color: #00A0E9;
  opacity: 1;
}

.input_button {
  width: 13.66%;
  height: 49px;
  background: linear-gradient(90deg, #0EA9F5 0%, #3A7AE7 100%);
  border-radius: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}

.input_button_1 {
  width: 13.66%;
  height: 49px;
  background: linear-gradient(90deg, #eecf0d 0%, #fc0d0d 100%);
  border-radius: 0 33px 33px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}

.get_id_box {
  display: flex;
  margin-top: 76px;
  height: auto;
  margin-left: 116px;
}

.get_id_button_box {
  width: 372px;
  background: url('~@/assets/bg1.png');
  background-size: 100%;
  background-repeat: no-repeat;

}

.get_id_title {
  height: 27px;
  font-size: 21px;
  font-weight: 300;
  color: #0DAAF5;
  text-align: center;
  margin-top: 11px;
}

.get_id_button {
  width: 108px;
  height: 30px;
  background: linear-gradient(
      90deg, #0EA9F5 0%, #3A7AE7 100%);
  border-radius: 21px;
  font-size: 18px;
  font-weight: 300;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 auto; */
  margin-left: 119px;
  margin-top: 11px;
  cursor: pointer;
}

.get_id_logo {

  border-radius: 10px;
  margin-top: 17%;
  width: 24%;
  margin-bottom: 3%;
}


</style>
